import {Dialog, DialogActions, DialogContentText, DialogTitle, Typography} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import React from "react";
import {TermsDialogTypes} from "./types";

export const TermsDialogDanish = (props: TermsDialogTypes) => {
    return (
        <Dialog
            open={props.show}
            onClose={props.onClose}>
            <DialogTitle>
                Handelsbetingelser
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography component="body">
                        DS Tinker ApS<br/>
                        Kærdalen 21<br/>
                        9850 Hirtshals<br/>
                        CVR nr.: 43831143<br/>
                        Telefon: (+0045) 51 93 55 00<br/>
                        Email: tinker2ds@gmail.com< br/>
                    </Typography>

                    <Typography component="h6">Priser</Typography>
                    <Typography component="body">Hos DS Tinker ApS er alle priserne i danske kroner og angivet inkl.
                        moms og afgifter. Vi forbeholder os ret til fra dag til dag at ændre i priserne uden forudgående
                        samtykke. Der tages forbehold for udsolgte varer.< br/></Typography>

                    <Typography component="h6">Betaling</Typography>
                    <Typography component="body">DS Tinker ApS modtager betaling med kort. Betalingen vil først blive
                        trukket på din konto, når varen afsendes. Alle beløb er i DKK. Danske kroner og incl. moms. Der
                        tages forbehold for prisfejl og udsolgte/udgåede varer.< br/></Typography>

                    <Typography component="h6">Levering</Typography>
                    <Typography component="body">DS Tinker ApS tilstræber at afsende ordre indgået inden kl. 17 samme
                        dag, ordre herefter sendes
                        næstfølgende hverdag.< br/></Typography>

                    <Typography component="h6">Reklamationsret</Typography>
                    <Typography component="body">Der gives 2 års reklamationsret i henhold til købeloven. Vores
                        reklamationsret gælder for fejl i
                        materiale og/eller fabrikation. Du kan få varen repareret, ombyttet, pengene retur eller afslag
                        i
                        prisen, afhængig af den konkrete situation. Reklamationen gælder ikke fejl eller skader begået
                        ved
                        forkert håndtering af produktet/ydelsen. Du skal reklamere i “rimelig tid” efter du har opdaget
                        manglen/fejlen. DS Tinker ApS vil dække returneringsomkostninger i et rimeligt omfang.< br/>

                        Ved returnering, reklamationer og benyttelse af fortrydelsesretten sendes til:< br/>
                        DS Tinker ApS< br/>
                        Kærdalen 21< br/>
                        9850 Hirtshals< br/>
                        Der modtages ikke forsendelser pr. efterkrav.< br/>
                    </Typography>

                    <Typography component="h6">Refusion</Typography>
                    <Typography component="body">Hvis der er tale om refusion, bedes du medsende bankoplysninger i form
                        af regnr og kontonr, så det
                        aftalte beløb kan overføres. Disse oplysninger kan uden risiko oplyses pr. mail eller anden
                        elektronisk form, da det ikke er følsomme oplysninger og kun vil blive anvendt til vores
                        opfyldelse
                        af refusionen.< br/></Typography>

                    <Typography component="h6">Fortrydelsesret</Typography>
                    <Typography component="body">Der gives 14 dages fuld returret på varer købt i vores
                        webshop.</Typography>

                    <Typography component="body">Køb af billetter til event eller eventrelaterede tillægsprodukter såsom
                        madbilletter, overnatning,
                        transport mm. er i henhold til – “Lov om visse forbrugsaftaler – §17 stk. 2 og §9 stk. 2 nr. 2a”
                        –
                        ikke omfattet af fortrydelsesret. Såfremt et arrangement aflyses refunderes billetten
                        naturligvis,
                        og beløbet bliver automatisk tilbageført på den trukne betalingskonto. Gebyrer refunderes ikke.
                        Perioden regnes fra den dag;< br/>
                        – Hvor du modtager ordren.< br/>
                        – Får den sidste vare i fysisk besiddelse, når det drejer sig om en aftale om flere forskellige
                        varer, bestilt i én ordre, men leveres enkeltvis eller af flere omgange.< br/>
                        – Får det sidste parti, eller sidste del i fysisk besiddelse, når det drejer sig om aftale af
                        levering af varer der består af flere partier/dele.< br/>
                        – Den første vare i fysis< br/>k besiddelse, når det drejer sig om regelmæssig levering af varer
                        over en
                        bestemt periode.
                        Returneringsomkostninger skal du selv afholde.< br/>
                        Fortrydelse skal anmeldes til os senest 14 efter købet og fra fortrydelsen skal I senest 14 dage
                        efter returnere forsendelsen. Meddelelsen skal gives pr. mail på tinker2ds@gmail.com. I
                        meddelelsen
                        skal du gøre tydeligt opmærksom på, at du ønsker at benytte din fortrydelsesret. Ønsker du at
                        sende
                        varen retur til os, skal du udfylde den vedlagte Returseddel og sende varen til:< br/>
                        DS Tinker ApS< br/>
                        Kærdalen 21< br/>
                        9850 Hirtshals< br/></Typography>

                    <Typography component="body">Du kan ikke fortryde ved blot at nægte modtagelse af varen, uden
                        samtidig at give tydelig meddelelse
                        herom.</Typography>

                    <Typography component="h6">Varer undtaget fortrydelsesretten</Typography>
                    <Typography component="body">Følgende varetyper indgår ikke i fortrydelsesretten:< br/>
                        – Varer, som er fremstillet efter forbrugerens specifikationer eller har fået et tydeligt
                        personligt
                        præg.< br/>
                        – Forseglede varer, som af sundhedsbeskyttelses- eller hygiejnemæssige årsager ikke er egnet til
                        at
                        blive returneret, og hvor forseglingen er brudt efter leveringen.< br/>
                        – Varer, der grundet sin art bliver uløseligt blandet sammen med andre ved levering.< br/>
                        – Varer, hvor plomberingen er brudt.< br/>
                        – Udførte ikke-finansielle tjenesteydelser, hvis levering af tjenesteydelsen er påbegyndt med
                        forbrugerens forudgående udtrykkelige samtykke og anderkendelse af, at fortrydelsesretten
                        ophører,
                        når tjenesteydelsen er fuldt udført.< br/>
                        – Levering af digitalt indhold, som ikke leveres på et fysisk medium, hvis udførelsen er
                        påbegyndt
                        med forbrugerens forudgående udtrykkelige samtykke og anerkendelse heraf, at vedkommende dermed
                        mister sin fortrydelsesret.< br/>
                        – Aviser, tidskrifter eller magasiner dog undtaget abonnementsaftaler for sådanne publikationer.
                        – Aftaler indgået på offentlig auktion.< br/>
                        – Varer, der forringes eller forældes hurtigt.- Billetter eller event relaterede produkter såsom
                        madbilletter, booking af transport eller overnatning mm.< br/></Typography>

                    <Typography component="h6">Returnering</Typography>
                    <Typography component="body">Du skal sende din ordre retur uden unødig forsinkelse og senest 14 dage
                        efter, at du har gjort brug
                        af din fortrydelsesret. Du skal afholde de direkte udgifter i forbindelse med returnering. Ved
                        returnering er du ansvarlig for, at varen er pakket ordentligt ind. Du skal vedlægge en kopi af
                        ordrebekræftelsen. Ekspeditionen går hurtigere, hvis du ligeledes udfylder og vedlægger vores
                        Fortrydelsesformular.< br/>
                        Du bærer risikoen for varen fra tidspunktet for varens levering og til, vi har modtaget den
                        retur.
                        Vi modtager ikke pakker sendt pr. efterkrav.< br/></Typography>

                    <Typography component="h6">Varens stand ved returnering</Typography>
                    <Typography component="body">Du hæfter kun for eventuel forringelse af varens værdi, som skyldes
                        anden håndtering, end hvad der
                        er nødvendigt for at fastslå varens art, egenskaber og den måde, hvorpå den fungerer. Du kan med
                        andre ord prøve varen, som hvis du prøvede den i en fysisk butik.< br/></Typography>

                    <Typography component="body">Hvis varen er prøvet udover, det ovenfor beskrevet, betragtes den som
                        brugt. Hvilket betyder, at du
                        ved fortrydelse af købet kun får en del eller intet af købsbeløbet retur, afhængig af varens
                        handelsmæssige værdi på modtagelsestidspunktet – af returneringen. For at modtage hele
                        købsbeløbet
                        retur må du altså afprøve varen uden egentlig at tage den i brug.< br/></Typography>

                    <Typography component="h6">Tilbagebetaling</Typography>
                    <Typography component="body">Fortryder du dit køb, får du naturligvis det beløb du har indbetalt til
                        os retur.
                        I tilfælde af en værdiforringelse, som du hæfter for, fratrækkes denne købs-beløbet.< br/>
                        Ved anvendelse af fortrydelsesretten, refunderes alle betalinger modtaget fra dig, herunder
                        leveringsomkostninger (undtaget ekstra omkostninger som følge af dit valg af en anden
                        leveringsform
                        end den billigste form for standardlevering, som vi tilbyder), uden unødig forsinkelse og senest
                        14
                        dage fra den dato, hvor vi har modtaget meddelelse om din beslutning om at gøre brug af
                        fortrydelsesretten. Tilbagebetaling gennemføres med samme betalingsmiddel, som du benyttede ved
                        den
                        oprindelige transaktion, medmindre du udtrykkeligt har indvilget i noget andet.< br/>
                        Vi kan tilbageholde beløbsrefunderingen, indtil vi har modtaget varen retur, med mindre du inden
                        da
                        har fremlagt dokumentation for at have returneret den.< br/></Typography>

                    <Typography component="h6">Persondatapolitik</Typography>
                    <Typography component="body">For at du kan indgå aftale med os og handle på vores hjemmeside, har vi
                        brug for følgende
                        oplysninger om dig:< br/>

                        Navn< br/>
                        Adresse< br/>
                        Telefonnummer< br/>
                        E-mailadresse< br/>
                        Oplysning om hvad du har købt
                    </Typography>
                    <Typography component="body">Vi behandler dine personoplysninger med det formål, at kunne levere
                        varen til dig, og for at kunne
                        behandle henvendelser vedrørende dit køb. Behandlingen sker efter reglerne i vores
                        persondatapolitik
                        for DS Tinker ApS. Heri kan du læse mere om, hvordan dine oplysninger behandles, hvornår de
                        slettes,
                        og hvilke rettigheder du har som registreret.< br/></Typography>

                    <Typography component="body"/>

                    <Typography component="h6">Klagemuligheder – oversigt og links:</Typography>
                    <Typography component="body">Har du en klage over et produkt, købt i vores Webshop, kan der sendes
                        en klage til:< br/>
                        Konkurrence- og Forbrugerstyrelsens Center for Klageløsning< br/>
                        Carl Jacobsens Vej 35< br/>
                        2500 Valby< br/>
                        Link: www.forbrug.dk< br/>< br/>

                        Hvis du er forbruger med bopæl i et andet EU-land, kan du angive din klage i EU Kommissionens
                        online
                        klageplatform.< br/>
                        Platformen findes her: http://ec.europa.eu/consumers/odr/< br/>
                        Angiver du en klage her, skal du oplyse vores E-mail adresse: tinker2ds@gmail.com</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} autoFocus>
                    LUK
                </Button>
            </DialogActions>
        </Dialog>
    );
}