import React from 'react'
import {App} from "./app";
import {APIProvider} from "./contexts";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import {createRoot} from 'react-dom/client';

const defaultLanguage = "en-US";

void i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
        lng: defaultLanguage,
        fallbackLng: defaultLanguage,
        debug: false,
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false
        }
    });

const renderApp = () => {
    const container = document.getElementById('app');
    const root = createRoot(container!);

    root.render(
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={(
                        <APIProvider>
                            <App/>
                        </APIProvider>
                    )}/>

                <Route
                    path="*"
                    element={
                        <Navigate to={"/"}/>
                    }/>
            </Routes>
        </BrowserRouter>
    );
};

renderApp();