import * as React from 'react';
import {IAPIContextProps, IAPIContextTypes} from './types';
import {APIService} from "../../services";
import {Booking} from "../../models/Booking";

export const APIContext = React.createContext({} as IAPIContextTypes);

export const APIProvider = (props: IAPIContextProps): React.ReactElement => {

  const getEvents = async (): Promise<Event[]> => {
    try {
      return APIService.get<Event[]>("event")
    } catch (e) {
      return new Promise(resolve => {
        resolve([])
      })
    }
  }

  const addBooking = async (booking: Booking): Promise<void> => {
    return APIService.post<void>(`booking`, booking)
  }

  return (
    <APIContext.Provider value={{
      getEvents: getEvents,
      addBooking: addBooking
    }}>
      {props.children}
    </APIContext.Provider>
  );
};

export const APIConsumer = APIContext.Consumer;
