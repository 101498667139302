import DialogContent from "@mui/material/DialogContent";
import {DialogActions, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import {IInfoDialogTypes} from "./types";
import {DateTime} from "luxon";
import "./info-dialog-content.scss"
import {useTranslation} from "react-i18next";
import {TermsDialogDanish} from "./TermsDialogDanish";
import i18next from "i18next";
import {useSearchParams} from "react-router-dom";
import {TermsDialogGerman} from "./TermsDialogGerman";
import {TermsDialogEnglish} from "./TermsDialogEnglish";
import {getDescription} from "../../utils/getDescription";

export const InfoDialogContent = (props: IInfoDialogTypes): React.ReactElement => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();

    const language = searchParams.get("lang") || "en-US";

    const startDate = DateTime.fromMillis(props.event ? props.event.startDate : 0);
    const endDate = DateTime.fromMillis(props.event ? props.event.endDate : 0);

    const [showTerms, setShowTerms] = React.useState<boolean>(false);

    const getPrice = () => {
        if (props.event && props.event.priceInEur !== null && props.event.priceInEur !== undefined && props.event.priceInDkk !== null && props.event.priceInDkk !== undefined) {
            if (language === "da-DK") {
                return props.event.priceInDkk + " kr.";
            } else {
                return props.event.priceInEur + " €.";
            }
        } else {
            return "";
        }
    }

    return (
        <>
            <TermsDialogDanish
                show={i18next.language === "da-DK" && showTerms}
                onClose={() => {
                    setShowTerms(false)
                }}/>

            <TermsDialogGerman
                show={i18next.language === "de-DE" && showTerms}
                onClose={() => {
                    setShowTerms(false)
                }}/>

            <TermsDialogEnglish
                show={i18next.language === "en-US" && showTerms}
                onClose={() => {
                    setShowTerms(false)
                }}/>

            <DialogContent className={"trip-info"}>
                <div>
                    <Typography>
                        {props.event ? getDescription(language, props.event, t) : t("no-description")}
                    </Typography>
                </div>
                <div className={"trip-info--metadata"}>
                    <Typography>
                        {t("trip-start")}
                        <b>{props.event ? startDate.setLocale(language).toLocaleString(DateTime.DATE_HUGE) : ""} {t("time")} {props.event ? startDate.toFormat("HH:mm") : ""}</b>.
                    </Typography>

                    <Typography>
                        {t("trip-end")}
                        <b>{props.event ? endDate.setLocale(language).toLocaleString(DateTime.DATE_HUGE) : ""} {t("time")} {props.event ? endDate.toFormat("HH:mm") : ""}</b>.
                    </Typography>

                    <Typography>
                        {(props.event && props.event.seatsAvailable <= 0) ? t("no-seats-available-booking") : ""}
                    </Typography>

                    <Typography>
                        {t("seats-available-p1")}
                        <b>{(props.event && props.event.seatsAvailable > 0) ? props.event.seatsAvailable : ""} {t("seats-available-p2")}</b>{t("seats-available-p3")}.

                        {props.event && (props.event.priceInDkk !== undefined && props.event.priceInDkk !== null && props.event.priceInEur !== undefined && props.event.priceInEur !== null) && (
                            <>
                                {` ${t("price-pr-seat")}`}
                                <b>{getPrice()}</b>
                            </>
                        )}
                    </Typography>
                    <Typography>
                        <br/>{t("terms-p1")} <a
                        target="_blank"
                        href="https://ds-tinker.dk/da/privacy-policy/"
                        style={{cursor: "pointer"}}><b>{t("terms-p2")}</b></a>.
                    </Typography>
                </div>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={props.onCancel}
                    variant={"contained"}
                    color={"primary"}>{t("cancel")}</Button>
                <Button
                    disabled={(props.event && props.event.seatsAvailable === 0)}
                    onClick={props.onStartBookingProcess}
                    variant={"contained"}
                    color={"success"}>{t("book")}</Button>
            </DialogActions>
        </>
    );
}