import {Dialog, DialogActions, DialogContentText, DialogTitle, Typography} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import React from "react";
import {TermsDialogTypes} from "./types";

export const TermsDialogGerman = (props: TermsDialogTypes) => {
    return (
        <Dialog
            open={props.show}
            onClose={props.onClose}>
            <DialogTitle>
                Allgemeine Geschäftsbedingungen
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    2.590 / 5.000
                    Oversættelsesresultater
                    Oversættelsen
                    <Typography component="body">
                        DS Tinker ApS<br/>
                        Kærdalen 21<br/>
                        9850 Hirtshals<br/>
                        CVR no.: 43831143<br/>
                        Telephone: (+0045) 51 93 55 00<br/>
                        Email: tinker2ds@gmail.com< br/>
                    </Typography>

                    <Typography component="h6">Prices</Typography>
                    <Typography component="body">At DS Tinker ApS, all prices are in Danish kroner and stated incl.
                        VAT and taxes. We reserve the right to change prices from day to day without prior notice
                        consent. Reservations are made for out-of-stock items.< br/></Typography>

                    <Typography component="h6">Payment</Typography>
                    <Typography component="body">DS Tinker ApS accepts payment by card. The payment will be first
                        debited from your account when the item is shipped. All amounts are in DKK. Danish kroner and incl. VAT. There
                        reservations are made for price errors and sold out/discontinued items.< br/></Typography>

                    <Typography component="h6">Delivery</Typography>
                    <Typography component="body">DS Tinker ApS aims to ship orders entered into before 17 same
                        day, order will then be sent
                        next weekday.< br/></Typography>

                    <Typography component="h6">Right of complaint</Typography>
                    <Typography component="body">There is a 2-year right of complaint in accordance with the Purchase Act. Our
                        right of complaint applies to errors in
                        material and/or manufacture. You can have the item repaired, exchanged, the money returned or refused
                        in
                        the price, depending on the specific situation. The complaint does not apply to mistakes or damage done
                        by
                        incorrect handling of the product/service. You must advertise in a "reasonable time" after you have discovered
                        the deficiency/error. DS Tinker ApS will cover return costs to a reasonable extent.< br/>

                        For returns, complaints and use of the right of withdrawal, send to:< br/>
                        DS Tinker ApS< br/>
                        Kærdalen 21< br/>
                        9850 Hirtshals< br/>
                        Shipments are not accepted per cash on delivery.< br/>
                    </Typography>
                    <Typography component="h6">Refund</Typography>
                    <Typography component="body">If it is a refund, please include bank details in the form
                        of account number and account number, so that
                        agreed amounts can be transferred. This information can be disclosed without risk per email or other
                        electronic form, as it is not sensitive information and will only be used for our purposes
                        fulfillment
                        of the refund.< br/></Typography>

                    <Typography component="h6">Right of withdrawal</Typography>
                    <Typography component="body">There is a 14-day full right of return on goods purchased from us
                        webshop.</Typography>

                    <Typography component="body">Purchase of tickets for events or event-related additional products such as
                        meal tickets, accommodation,
                        transport etc. is according to - "Act on certain consumption agreements - §17 subsection 2 and §9 subsection 2 no. 2a"
                        –
                        not covered by the right of withdrawal. If an event is cancelled, the ticket will be refunded
                        of course,
                        and the amount is automatically returned to the debited payment account. Fees are non-refundable.
                        The period is counted from that day;< br/>
                        – Where you receive the order.< br/>
                        - Gets physical possession of the last item when it concerns an agreement for several different ones
                        goods, ordered in one order, but delivered individually or in several batches.< br/>
                        – Gets the last lot, or last part in physical possession, when it comes to agreement of
                        delivery of goods consisting of several lots/parts.< br/>
                        – The first item in physical< br/>k possession in the case of regular delivery of goods
                        over one
                        certain period.
                        Return costs are your own responsibility.< br/>
                        Cancellations must be reported to us no later than 14 days after the purchase, and from the cancellation you must no later than 14 days
                        after returning the shipment. The notice must be given by mail at tinker2ds@gmail.com. IN
                        the message
                        you must make it clear that you wish to exercise your right of withdrawal. Do you wish to
                        send
                        return the item to us, you must fill in the attached return slip and send the item to:< br/>
                        DS Tinker ApS< br/>
                        Kærdalen 21< br/>
                        9850 Hirtshals<br/></Typography>

                    <Typography component="body">You cannot cancel by simply refusing to receive the item, without
                        while giving a clear message
                        about this.</Typography>

                    <Typography component="h6">Goods excluded from the right of withdrawal</Typography>
                    <Typography component="body">The following product types are not included in the right of withdrawal:< br/>
                        – Goods which are manufactured according to the consumer's specifications or have been given a clear one
                        personally
                        mark.< br/>
                        – Sealed goods which are not suitable for health protection or hygiene reasons
                        to
                        be returned and where the seal is broken after delivery.< br/>
                        – Goods which, due to their nature, are inextricably mixed with others upon delivery.< br/>
                        – Goods where the sealing is broken.< br/>
                        – Performed non-financial services, if the provision of the service has begun with
                        the consumer's prior express consent and acknowledgment that the right of withdrawal
                        ceases
                        when the service is fully performed.< br/>
                        – Delivery of digital content that is not delivered on a physical medium if the performance is
                        commenced
                        with the consumer's prior express consent and acknowledgment thereof, that the person thus
                        loses his right of withdrawal.< br/>
                        – Newspapers, magazines or magazines, however, excluding subscription agreements for such publications.
                        – Agreements concluded at public auction.< br/>
                        – Goods that deteriorate or become obsolete quickly.- Tickets or event-related products such as
                        meal tickets, booking transport or accommodation etc.< br/></Typography>

                    <Typography component="h6">Return</Typography>
                    <Typography component="body">You must send your order back without undue delay and no later than 14 days
                        after you have used it
                        of your right of withdrawal. You must bear the direct costs in connection with the return. By
                        return, you are responsible for the item being properly packed. You must attach a copy of
                        the order confirmation. The dispatch goes faster if you also fill in and attach our
                        Cancellation form.< br/>
                        You bear the risk for the goods from the time of delivery of the goods until we have received them
                        return.
                        We do not receive packages sent by cash on delivery.< br/></Typography>

                    <Typography component="h6">Item condition upon return</Typography>
                    <Typography component="body">You are only liable for any deterioration in the product's value that is due
                        other handling than what
                        is necessary to establish the nature of the goods, their characteristics and the way in which they function. You can join
                        in other words try the product, as if you tried it in a physical store.< br/></Typography>

                    <Typography component="body">If the item is tested in addition to what is described above, it is considered as
                        used. Which means you
                        in case of cancellation of the purchase, only part or none of the purchase amount will be returned, depending on the product
                        commercial value at the time of receipt – of the return. To receive the whole
                        the purchase amount
                        In return, you must therefore test the product without actually using it.< br/></Typography>

                    <Typography component="h6">Refund</Typography>
                    <Typography component="body">If you regret your purchase, you will of course receive the amount you paid for
                        us back.
                        In the event of a decrease in value for which you are liable, this will be deducted from the purchase amount.< br/>
                        When using the right of withdrawal, all payments received from you will be refunded, including
                        delivery costs (excluding additional costs resulting from your choice of another
                        form of delivery
                        than the cheapest form of standard delivery that we offer), without undue delay and at the latest
                        14
                        days from the date on which we received notification of your decision to make use of
                        the right of withdrawal. Refunds are made with the same means of payment that you used
                        the
                        original transaction, unless you have expressly agreed otherwise.< br/>
                        We can withhold the refund until we have received the item back, unless you before
                        as
                        has provided documentation of having returned it.< br/></Typography>

                    <Typography component="h6">Personal data policy</Typography>

                    <Typography component="body">In order for you to enter into an agreement with us and shop on our website, we have
                        need the following
                        information about you:< br/>

                        Name< br/>
                        Address< br/>
                        Phone number< br/>
                        Email address< br/>
                        Information about what you have purchased
                    </Typography>
                    <Typography component="body">We process your personal data for the purpose of being able to deliver
                        the item for you, and to be able to
                        process inquiries regarding your purchase. The processing takes place according to the rules in our
                        personal data policy
                        for DS Tinker ApS. Here you can read more about how your information is processed and when
                        deleted,
                        and what rights you have as registered.< br/></Typography>

                    <Typography component="body"/>

                    <Typography component="h6">Complaint options – overview and links:</Typography>
                    <Typography component="body">If you have a complaint about a product bought in our Webshop, it can be sent
                        a complaint to:< br/>
                        The Danish Competition and Consumer Authority's Center for Complaint Resolution< br/>
                        Carl Jacobsens Vej 35< br/>
                        2500 Valby< br/>
                        Link: www.forbrug.dk< br/>< br/>

                        If you are a consumer residing in another EU country, you can register your complaint with the EU Commission
                        online
                        complaint platform.< br/>
                        The platform can be found here: http://ec.europa.eu/consumers/odr/< br/>
                        If you submit a complaint here, you must provide our email address: tinker2ds@gmail.com</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}