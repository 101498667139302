import React from "react";
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import {
    Box,
    CircularProgress,
    DialogActions,
    DialogContentText, FormControl,
    Grid, InputLabel, MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {green} from "@mui/material/colors";
import {v4 as uuidv4} from 'uuid';
import {IBookingDialogContentTypes} from "./types";
import {useTranslation} from "react-i18next";
import {Booking} from "../../models/Booking";

export const BookingDialogContent = (props: IBookingDialogContentTypes): React.ReactElement => {
    const {t} = useTranslation();
    const defaultBooking: Booking = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        contactManager: true,
        eventId: -1,
        bookingId: -1,
        seats: 1,
        note: ""
    };

    const [booking, setBooking] = React.useState<Booking>(defaultBooking);
    const [firstNameError, setFirstNameError] = React.useState<boolean>(false);
    const [lastNameError, setLastNameError] = React.useState<boolean>(false);
    const [emailError, setEmailError] = React.useState<boolean>(false);
    const [phoneError, setPhoneError] = React.useState<boolean>(false);
    const [seatsError, setSeatsError] = React.useState<boolean>(false);
    const [noteError, setNoteError] = React.useState<boolean>(false);

    const resetStates = () => {
        setFirstNameError(false);
    };

    const isFormValid = (): boolean => {
        let firstNameValid: boolean = true;
        let lastNameValid: boolean = true;
        let emailValid: boolean = true;
        let phoneValid: boolean = true;
        let noteValid: boolean = true;

        if (!booking.firstName || booking.firstName.trim().length === 0) {
            firstNameValid = false;
        }

        if (!booking.lastName || booking.lastName.trim().length === 0) {
            lastNameValid = false;
        }

        if (!booking.email || booking.email.trim().length === 0) {
            emailValid = false;
        }

        if (!booking.phone || booking.phone.trim().length === 0) {
            phoneValid = false;
        }

        if (!booking.phone || booking.phone.trim().length === 0) {
            phoneValid = false;
        }

        if (booking.note.trim().length > 250) {
            noteValid = false;
        }

        return firstNameValid && lastNameValid && emailValid && phoneValid && noteValid;
    }

    const validateFirstName = (firstName: string): void => {
        let firstNameValid: boolean = false;
        if (firstName && firstName.trim().length > 1 && firstName.trim().length < 100) {
            firstNameValid = true;
        }

        setFirstNameError(!firstNameValid);
    }

    const validateLastName = (lastName: string): void => {
        let lastNameValid: boolean = false;
        if (lastName && lastName.trim().length > 1 && lastName.trim().length < 100) {
            lastNameValid = true;
        }

        setLastNameError(!lastNameValid);
    }

    const validateEmail = (email: string): void => {
        let emailValid: boolean = false;
        if (email && email.trim().length > 2 && email.includes("@") && email.includes(".") && email.trim().length < 100) {
            emailValid = true;
        }

        setEmailError(!emailValid);
    }

    const validatePhone = (phone: string): void => {
        let phoneValid: boolean = false;
        if (phone && phone.trim().length > 3 && phone.trim().length < 25) {
            phoneValid = true;
        }

        setPhoneError(!phoneValid);
    }

    const validateSeats = (seats: number): void => {
        let seatsValid: boolean = false;
        if (seats && seats > 0 && seats <= props.event.seatsAvailable) {
            seatsValid = true;
        }

        setSeatsError(!seatsValid);
    }

    const validateNote = (note: string): void => {
        let noteValid: boolean = false;
        if (note && note.length > 0 && note.length <= 255) {
            noteValid = true;
        }

        setNoteError(!noteValid);
    }

    const updateBooking = (booking: Booking, prop: string, value: string | number): void => {
        const tempBooking = {...booking};
        tempBooking[prop] = value;
        setBooking(tempBooking)
    }

    return (
        <>
            <DialogContent>
                <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12}>
                        <DialogContentText>
                            <Typography>
                                {t('booking-info')}
                            </Typography>
                        </DialogContentText>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    disabled={props.loading}
                                    value={booking.firstName}
                                    onChange={(event) => {
                                        validateFirstName(event.target.value);
                                        updateBooking(booking, "firstName", event.target.value)
                                    }}
                                    error={firstNameError}
                                    autoFocus
                                    margin="dense"
                                    label={t("first-name-placeholder")}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    disabled={props.loading}
                                    error={lastNameError}
                                    value={booking.lastName}
                                    onChange={(event) => {
                                        validateLastName(event.target.value)
                                        updateBooking(booking, "lastName", event.target.value)
                                    }}
                                    margin="dense"
                                    label={t("last-name-placeholder")}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    disabled={props.loading}
                                    value={booking.email}
                                    onChange={(event) => {
                                        validateEmail(event.target.value);
                                        updateBooking(booking, "email", event.target.value)
                                    }}
                                    error={emailError}
                                    margin="dense"
                                    label={t("email-placeholder")}
                                    type="email"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    disabled={props.loading}
                                    value={booking.phone}
                                    onChange={(event) => {
                                        validatePhone(event.target.value);
                                        updateBooking(booking, "phone", event.target.value)
                                    }}
                                    margin="dense"
                                    label={t("phone-placeholder")}
                                    error={phoneError}
                                    type="tel"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item={true} xs={12}>
                                <FormControl
                                    margin="dense"
                                    fullWidth>
                                    <InputLabel>{t("seats")}</InputLabel>

                                    <Select
                                        label={t("seats")}
                                        error={seatsError}
                                        disabled={props.loading}
                                        value={booking.seats}
                                        onChange={(event) => {
                                            validateSeats(Number(event.target.value));
                                            updateBooking(booking, "seats", event.target.value)
                                        }}>
                                        {Array(props.event.seatsAvailable).fill(0).map((value, index) => (
                                            <MenuItem
                                                key={index+1}
                                                value={index+1}>
                                                {index +1}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <TextField
                                  rows={6}
                                  minRows={4}
                                  maxRows={8}
                                  multiline={true}
                                  disabled={props.loading}
                                  value={booking.note}
                                  onChange={(event) => {
                                      validateNote(event.target.value);
                                      updateBooking(booking, "note", event.target.value)
                                  }}
                                  margin="dense"
                                  label={t("note-placeholder")}
                                  error={noteError}
                                  type="text"
                                  fullWidth
                                  variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    props.onCancel();
                    resetStates();
                }} variant={"contained"} color={"primary"}>{t("cancel")}</Button>
                <Box sx={{m: 1, position: 'relative'}}>
                    <Button
                        disabled={!isFormValid() || props.loading}
                        variant={"contained"}
                        color={"success"}
                        onClick={() => {
                            if (isFormValid()) {
                                const randomId = uuidv4();
                                booking.eventId = props.event.ID;
                                booking.bookingId = `${props.event.ID},${randomId}`

                                props.onApply(booking);

                                // TODO: This is annoying
                                resetStates();
                            }
                        }}>{t("done")}</Button>
                    {props.loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Box>
            </DialogActions>
        </>
    );
}